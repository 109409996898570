import React, { useEffect, useState, useCallback } from "react";
import "@sia/style-guide/dist/base.css";
import { Route, Switch, useHistory } from "react-router-dom";
import { init as initI18n, getBrowserLanguageKey, getLocale } from "common/Translation/i18n";
import IDPAdapter from "common/Adapters/IDPAdapter/IDPAdapter";
import PrivateRoute from "./PrivateRoute";
import CustomerDataPage from "./pages/Account/CustomerData/CustomerDataPage";
import LoginInformationPage from "pages/Account/CustomerData/LoginInformationPage";
import BaseDataPage from "pages/Account/CustomerData/BaseDataPage";
import PersonalDataPage from "pages/Account/CustomerData/PersonalDataPage";
import AddressesPage from "pages/Account/CustomerData/AddressesPage/AddressesPage";
import FormationPage from "pages/Account/CustomerData/Formation/FormationPage";
import MembershipsPage from "pages/Account/CustomerData/Memberships/MembershipsPage";
import InvoicesPage from "./pages/Account/Invoices/InvoicesPage";
import ReferenceGalleryPage from "./pages/ReferenceGallery/ReferenceGalleryPage";
import ProjectPage from "./pages/ReferenceGallery/Project/ProjectPage";
import ManageProjectPage from "./pages/ReferenceGallery/Manage/ManageProjectPage";
import NotFoundPage from "./pages/Error/NotFoundPage";
import GlobalContext from "contexts/GlobalContext";
import { useTranslation } from "react-i18next";
import { useTranslator } from "./common/Translation/Translator";
import { Redirect } from "react-router-dom";
import httpClient from "common/HttpClient";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import User from "./common/User";
import { usePathFactory } from "./common/Path/PathFactoryHook";
import ManageProjectsPage from "./pages/ReferenceGallery/Manage/ManageProjectsPage";
import JobOffersPage from "./pages/Jobs/JobOffersPage";
import memberTypeRepository from "common/Adapters/WebPlatformAdapter/MemberTypes/MemberTypeRepository";
import { Body, Footer, Layout, Loader, safeStateChanger, PrivacyPolicyNotification } from "@sia/style-guide";
import PrivacyPolicyPage from "./pages/PrivacyPolicy/PrivacyPolicyPage";
import { useFooterContents } from "./common/Layout/FooterContents";
import DirectoryRoutes from "pages/Directory/Routes";
import isGoogleBot from "./common/GoogleBots";
import OffersOverviewPage from "pages/Jobs/Manage/OffersOverviewPage";
import ManageOfferPage from "pages/Jobs/Manage/ManageOfferPage";
import AgendaAppPage from "pages/Agenda/AgendaAppPage";
import JobOfferDetailsPage from "pages/Jobs/Details/JobOfferDetailsPage";
import JobOfferPreviewPage from "pages/Jobs/Preview/JobOfferPreviewPage";
import ServicesPage from "pages/Account/ServicesPage";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ErrorPage from "./pages/Error/ErrorPage";
import HttpErrorContextProvider from "contexts/HttpErrorContext";
import SubsidaryAddressesPage from "pages/Account/CustomerData/AddressesPage/SubsidaryAddressesPage";
import ManageDirectory from "pages/Account/CustomerData/Directory/ManageDirectory";
import CMSContent from "pages/CMSContent/CMSContent";
import cmsAdapter from "./common/Adapters/CMSAdapter/CMSAdapter";
import CMSSearch from "pages/CMSContent/CMSSearch";
import WizardProvider from "contexts/WizardContext";
import CookieClient from "./components/CookieClient";
import UnsubscribeFromNewsletter from "pages/Newsletter/UnsubscribePage";
import HeaderClient from "components/HeaderClient";
import Catalog from "pages/Catalog/Catalog";
import Agenda from "pages/Agenda/Agenda";
import CreditCardPayment from "pages/CreditCardPayment/CreditCardPayment";

initI18n();

function App() {
  const { i18n } = useTranslation();
  const translate = useTranslator();
  const history = useHistory();
  const pathFactory = usePathFactory();
  const [isLoaderShown, setIsLoaderShown] = useState(false);
  httpClient.setLoaderStateCallback(setIsLoaderShown);
  const [menuItems, setMenuItems] = useState(process.env.REACT_APP_CMS_URL ? [] : undefined);
  const [noMenuPages, setNoMenuPages] = useState(process.env.REACT_APP_CMS_URL ? [] : undefined);
  const [isLoadingPages, setIsLoadingPages] = useState(process.env.REACT_APP_CMS_URL ? true : false);
  const [user, setUser] = useState(null);
  const [headerUser, setHeaderUser] = useState(null);
  const [isIDPInitialized, setIsIDPInitialized] = useState(false);
  const [wasIDPInitializationStarted, setWasIDPInitializationStarted] = useState(false);
  const [isSubscribedToJobs, setIsSubscribedToJobs] = useState(false);
  const [language, setLanguage] = useState(null);
  const footerContents = useFooterContents(language);
  const [isSubscribedToAnyService, setIsSubscribedToAnyService] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState({
    showNotification: false,
    answer: null,
  });

  const referenceGalleryInitialFilters = {};
  const [referenceGalleryFilters, setReferenceGalleryFilters] = useState(referenceGalleryInitialFilters);
  const referenceGalleryPageInitialsArgs = {
    chosenSort: {
      id: "newest",
      label: translate("reference-gallery.sort.newest"),
    },
    targetPage: 1,
    perPageLimit: 10,
  };
  const [referenceGalleryPageArgs, setReferenceGalleryPageArgs] = useState(referenceGalleryPageInitialsArgs);

  const jobOffersInitialFilters = {
    jobs: [],
    regions: [],
    contractTypes: [],
  };
  const [jobOffersFilters, setJobOffersFilters] = useState(jobOffersInitialFilters);
  const jobOffersPageInitialArgs = {
    chosenSort: { id: "newest", label: translate("jobs.search.sort.newest") },
  };
  const [jobOffersPageArgs, setJobOffersPageArgs] = useState(jobOffersPageInitialArgs);

  const localStorageLanguageKey = localStorage.getItem("sia.webPlatform.languageKey");
  const globalContextValue = {
    isLoaderShown,
    setIsLoaderShown,
    isIDPInitialized: isIDPInitialized,
    user: user,
    language,
    isSubscribedToAnyService,
    menuItems,
    noMenuPages,
  };
  const defaultAppSwitcherValue = isGoogleBot(navigator.userAgent);
  const getUrlLanguageKey = useCallback(() => {
    const pathSplit = history.location.pathname.split("/");
    return pathSplit[1];
  }, [history.location.pathname]);

  const isValidLanguageKey = useCallback((languageKey) => Object.keys(i18n.options.resources).includes(languageKey), [i18n.options.resources]);

  const getApplyingLanguage = useCallback(() => {
    const urlLanguageKey = getUrlLanguageKey();
    const validUrlLanguageKey = isValidLanguageKey(urlLanguageKey) ? urlLanguageKey : null;
    if (validUrlLanguageKey) {
      return validUrlLanguageKey;
    }
    if (localStorageLanguageKey) {
      return localStorageLanguageKey;
    }
    return getBrowserLanguageKey();
  }, [localStorageLanguageKey, getUrlLanguageKey, isValidLanguageKey]);

  const handlePrivacyPolicyNotification = useCallback(() => {
    if (window.location.pathname.includes("privacy-policy")) {
      return;
    }
    // todo SW-412, endpoint should be GET instead of POST
    webPlatformAdapter.request("POST", "rpc/show_privacy_policy", null, (response) => {
      setPrivacyPolicy(safeStateChanger.changeValue(privacyPolicy, "showNotification", response.data.show));
    });
  }, [privacyPolicy]);

  const answerPrivacyPolicyNotification = () => {
    setPrivacyPolicy(safeStateChanger.changeValue(privacyPolicy, "showNotification", false));
    const data = {
      answer: privacyPolicy.answer === "yes",
      application: window.location.href,
      language: getLocale(),
    };
    webPlatformAdapter.request("POST", "rpc/accept_privacy_policy", data);
  };
  const createHeaderUser = (user, customerData, originalMemberType) => {
    const id = parseFloat(user.getSiaId());
    if (user.isMemberTypeGroupOf("single")) {
      return {
        id: id,
        name: [customerData.firstname, customerData.lastname].join(" "),
        type: originalMemberType,
      };
    }
    if (user.isMemberTypeGroupOf("corporate")) {
      return {
        id: id,
        name: customerData.name,
        additionalName: customerData.additional_name,
        type: originalMemberType,
      };
    }
  };

  useEffect(() => {
    if (wasIDPInitializationStarted) {
      return;
    }
    i18n.changeLanguage(getApplyingLanguage(), () => {
      setLanguage(i18n.language);
      process.env.REACT_APP_CMS_URL && loadMenu(i18n.language);
      process.env.REACT_APP_CMS_URL && loadAliases(i18n.language);
      setJobOffersPageArgs({
        chosenSort: {
          id: "newest",
          label: translate("jobs.search.sort.newest"),
        },
      });
      setReferenceGalleryPageArgs((state) => ({
        ...state,
        chosenSort: {
          id: "newest",
          label: translate("reference-gallery.sort.newest"),
        },
      }));
    });
    setIsLoaderShown(true); // todo, refactor loader handling SW-167
    setWasIDPInitializationStarted(true);
    IDPAdapter.init((idpUser) => {
      setIsIDPInitialized(true);
      setIsLoaderShown(false);
      if (idpUser) {
        if (localStorage.getItem("sia.webPlatform.forceUserLanguage")) {
          localStorage.removeItem("sia.webPlatform.forceUserLanguage");
          localStorage.setItem("sia.webPlatform.languageKey", idpUser.getLocale());
          const languageKeyReplacedUrl = createLanguageKeyReplacedUrl(idpUser.getLocale());
          window.location.replace(languageKeyReplacedUrl);
          return;
        }
        const servicesRoles = ["sia_role_member_corporate", "sia_role_member_single"];
        if (servicesRoles.includes(idpUser.role)) {
          setIsSubscribedToAnyService(true);
        }
        webPlatformAdapter.getLoginInformation(i18n.language, (response) => {
          const originalMemberType = response.data[0].member_type.name;
          const memberType = memberTypeRepository.getById(response.data[0].member_type.id);
          const hasSubsidiaries = response.data[0].has_subsidiaries;
          const user = new User(idpUser.getUsername(), memberType, idpUser.getLocale(), idpUser.getRole(), originalMemberType, hasSubsidiaries);
          webPlatformAdapter.getCustomerData(user, (response) => {
            const customerData = response.data[0];
            setHeaderUser(createHeaderUser(user, customerData, originalMemberType));
            setUser(user);
          });
        });
        handlePrivacyPolicyNotification();
      }
    });
    // eslint-disable-next-line
  }, [wasIDPInitializationStarted, i18n, getApplyingLanguage, handlePrivacyPolicyNotification, translate, loadMenu]);

  useEffect(() => {
    if (user !== null) {
      webPlatformAdapter.getIsJobSubscribed((response) => {
        const { data } = response;
        const job_subscribed = data.job_subscribed;
        const CORPORATE = "sia_role_member_corporate";
        if (job_subscribed && user.role === CORPORATE) {
          setIsSubscribedToJobs(true);
        }
      });
    }
  }, [user, language]);

  const createLanguageKeyReplacedUrl = (languageKey) => {
    let pathParts = window.location.pathname.split("/");
    pathParts[1] = languageKey;
    const hash = window.location.hash ? window.location.hash : "";
    const search = window.location.search ? window.location.search : "";
    return window.location.origin + pathParts.join("/") + search + hash;
  };

  const renderPage = (component) => {
    const urlLanguageKey = getUrlLanguageKey();
    if (urlLanguageKey && !isValidLanguageKey(urlLanguageKey)) {
      return <NotFoundPage />;
    }
    return component;
  };

  async function loadMenu(lang) {
    await cmsAdapter.fetchMenuItems(
      lang,
      ({ menuItems }) => {
        setMenuItems(menuItems);
        loadNoMenu(lang);
      },
      history
    );
  }
  async function loadNoMenu(lang) {
    await cmsAdapter.fetchNoMenu(lang, ({ data }) => {
      setNoMenuPages(data);
      setIsLoadingPages(false);
    });
  }
  async function loadAliases(lang) {
    const alias = history.location.pathname;
    await cmsAdapter.fetchAliases(lang, alias, (data) => {
      const findAlias = data?.length > 0 && data[0];
      if (findAlias?.alias) {
        const usedLang = findAlias.lang || lang;
        if (usedLang !== lang) localStorage.setItem("sia.webPlatform.languageKey", usedLang);
        if (findAlias.destination) {
          window.location.href = findAlias.destination.startsWith("http") ? findAlias.destination : `/${usedLang}${findAlias.destination}`;
        } else {
          const to = `/${usedLang}/cms/node/${findAlias.node}${findAlias.section_element ? `#${findAlias.section_element}` : ""}`;
          window.location.replace(to);
        }
      }
    });
  }
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <html lang={i18n.language} />
        </Helmet>
      </HelmetProvider>
      {isLoaderShown && <Loader />}
      <Layout>
        {
          <HeaderClient
            pathFactory={pathFactory}
            history={history}
            setReferenceGalleryFilters={setReferenceGalleryFilters}
            setReferenceGalleryPageArgs={setReferenceGalleryPageArgs}
            referenceGalleryInitialFilters={referenceGalleryInitialFilters}
            referenceGalleryPageInitialsArgs={referenceGalleryPageInitialsArgs}
            jobOffersInitialFilters={jobOffersInitialFilters}
            jobOffersPageInitialArgs={jobOffersPageInitialArgs}
            setJobOffersFilters={setJobOffersFilters}
            setJobOffersPageArgs={setJobOffersPageArgs}
            user={user}
            menuItems={menuItems}
            i18n={i18n}
            createLanguageKeyReplacedUrl={createLanguageKeyReplacedUrl}
            translate={translate}
            headerUser={headerUser}
            defaultAppSwitcherValue={defaultAppSwitcherValue}
            language={language}
          />
        }
        <Body>
          <GlobalContext.Provider value={globalContextValue}>
            <HttpErrorContextProvider>
              <WizardProvider>
                <Switch>
                  <Route exact path="/" component={() => <Redirect to={`/${getApplyingLanguage()}`} />} />
                  <Route exact path="/privacy-policy" render={() => <Redirect to={`${getApplyingLanguage()}/privacy-policy`} />} />
                  <Route exact path="/:languageKey" render={() => renderPage(<CMSContent nodeId={590} />)} />
                  <Route exact path="/pay" component={() => <Redirect to={`/${getApplyingLanguage()}/pay`} />} />
                  <Route exact path="/:languageKey/pay" render={() => renderPage(<CreditCardPayment />)} />
                  <Route path="/:languageKey/pay/:status" render={() => renderPage(<CreditCardPayment />)} />
                  <Route
                    exact
                    path="/:languageKey/privacy-policy"
                    render={() => renderPage(<PrivacyPolicyPage handlePrivacyPolicyNotification={handlePrivacyPolicyNotification} />)}
                  />

                  <PrivateRoute exact path="/:languageKey/account" render={() => renderPage(<Redirect to={`account/customer-data`} />)} />
                  <PrivateRoute exact path="/:languageKey/account/customer-data" render={() => renderPage(<CustomerDataPage />)} />
                  <PrivateRoute path="/:languageKey/account/customer-data/login-information" render={() => renderPage(<LoginInformationPage />)} />
                  <PrivateRoute path="/:languageKey/account/customer-data/personal-data" render={() => renderPage(<PersonalDataPage />)} />
                  <PrivateRoute path="/:languageKey/account/customer-data/base-data" render={() => renderPage(<BaseDataPage />)} />
                  <PrivateRoute exact path="/:languageKey/account/customer-data/addresses" render={() => renderPage(<AddressesPage />)} />
                  <PrivateRoute
                    path="/:languageKey/account/customer-data/addresses/subsidiary"
                    render={() => renderPage(<SubsidaryAddressesPage />)}
                  />
                  <PrivateRoute path="/:languageKey/account/customer-data/directory" render={() => renderPage(<ManageDirectory />)} />
                  <PrivateRoute path="/:languageKey/account/customer-data/formation" render={() => renderPage(<FormationPage />)} />
                  <PrivateRoute path="/:languageKey/account/customer-data/memberships" render={() => renderPage(<MembershipsPage />)} />
                  {/* todo, temporary hidden by SW-244 */}
                  <PrivateRoute
                    path="/:languageKey/account/services"
                    render={() => renderPage(<ServicesPage setIsSubscribedToJobs={setIsSubscribedToJobs} />)}
                  />
                  <PrivateRoute path="/:languageKey/account/invoices" render={() => renderPage(<InvoicesPage />)} />

                  <Route exact path="/:languageKey/reference-gallery" render={() => renderPage(<Redirect to={`reference-gallery/search`} />)} />
                  <Route
                    exact
                    path="/:languageKey/reference-gallery/search"
                    render={() =>
                      renderPage(
                        <ReferenceGalleryPage
                          initialFilters={referenceGalleryFilters}
                          setInitialFilters={setReferenceGalleryFilters}
                          referenceGalleryPageArgs={referenceGalleryPageArgs}
                          setReferenceGalleryPageArgs={setReferenceGalleryPageArgs}
                        />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/:languageKey/reference-gallery/search/:ownerId"
                    render={() =>
                      renderPage(
                        <ReferenceGalleryPage
                          initialFilters={referenceGalleryFilters}
                          setInitialFilters={setReferenceGalleryFilters}
                          referenceGalleryPageArgs={referenceGalleryPageArgs}
                          setReferenceGalleryPageArgs={setReferenceGalleryPageArgs}
                        />
                      )
                    }
                  />
                  <Route path="/:languageKey/reference-gallery/search/0/project/:id" render={() => renderPage(<ProjectPage />)} />
                  <Route
                    path="/:languageKey/reference-gallery/search/project/:id"
                    render={() => renderPage(<ProjectPage setReferenceGalleryPageArgs={setReferenceGalleryPageArgs} />)}
                  />
                  <PrivateRoute
                    exact
                    path="/:languageKey/reference-gallery/manage"
                    render={() => renderPage(<ManageProjectsPage setReferenceGalleryPageArgs={setReferenceGalleryPageArgs} />)}
                    allowedMemberTypes={["corporate", "subsidiaryCompany"]}
                  />
                  <PrivateRoute
                    exact
                    path="/:languageKey/reference-gallery/manage/project"
                    render={() => renderPage(<ManageProjectPage setReferenceGalleryPageArgs={setReferenceGalleryPageArgs} />)}
                  />
                  <PrivateRoute
                    exact
                    path="/:languageKey/reference-gallery/manage/project/:id"
                    render={() => renderPage(<ManageProjectPage setReferenceGalleryPageArgs={setReferenceGalleryPageArgs} />)}
                  />
                  <PrivateRoute
                    path="/:languageKey/reference-gallery/manage/project/:id/preview"
                    render={() => renderPage(<ProjectPage setReferenceGalleryPageArgs={setReferenceGalleryPageArgs} />)}
                  />
                  <Route path="/:languageKey/directory" render={() => renderPage(<DirectoryRoutes />)} />
                  <Route exact path="/:languageKey/jobs" render={() => renderPage(<Redirect to={"jobs/offers"} />)} />
                  <Route exact path="/:languageKey/jobs/0" render={() => renderPage(<Redirect to={pathFactory.create("jobs/offers")} />)} />
                  <Route
                    exact
                    path="/:languageKey/jobs/offers"
                    render={() =>
                      renderPage(
                        <JobOffersPage
                          jobOffersFilters={jobOffersFilters}
                          setJobOffersFilters={setJobOffersFilters}
                          jobOffersPageArgs={jobOffersPageArgs}
                          setJobOffersPageArgs={setJobOffersPageArgs}
                          isSubscribedToJobs={isSubscribedToJobs}
                        />
                      )
                    }
                  />

                  <Route
                    path="/:languageKey/jobs/offers/:id"
                    render={() =>
                      renderPage(
                        <JobOfferDetailsPage
                          setJobOffersFilters={setJobOffersFilters}
                          setJobOffersPageArgs={setJobOffersPageArgs}
                          isSubscribedToJobs={isSubscribedToJobs}
                        />
                      )
                    }
                  />
                  <PrivateRoute
                    path="/:languageKey/jobs/manage/offer/:id/preview"
                    render={() =>
                      renderPage(
                        <JobOfferPreviewPage
                          setJobOffersFilters={setJobOffersFilters}
                          setJobOffersPageArgs={setJobOffersPageArgs}
                          isSubscribedToJobs={isSubscribedToJobs}
                        />
                      )
                    }
                  />
                  <PrivateRoute
                    exact
                    path="/:languageKey/jobs/manage"
                    render={() => renderPage(<OffersOverviewPage isSubscribedToJobs={isSubscribedToJobs} />)}
                  />
                  <PrivateRoute
                    exact
                    path="/:languageKey/jobs/manage/offer"
                    render={() => renderPage(<ManageOfferPage isSubscribedToJobs={isSubscribedToJobs} />)}
                  />
                  <PrivateRoute
                    exact
                    path="/:languageKey/jobs/manage/offer/:id"
                    render={() => renderPage(<ManageOfferPage isSubscribedToJobs={isSubscribedToJobs} />)}
                  />

                  <Route exact path="/:languageKey/agenda" render={() => renderPage(<Agenda />)} />
                  <Route exact path="/:languageKey/agenda/:id" render={() => renderPage(<AgendaAppPage isEventOccurrence={true} />)} />
                  <Route exact path="/:languageKey/agenda/speaker/:id" render={() => renderPage(<AgendaAppPage />)} />

                  {noMenuPages?.map((page) => {
                    if (page.alias.includes("inform/feedback")) {
                      return (
                        <Route
                          key={page.id}
                          path={`/:languageKey/agenda${page.alias}`}
                          render={() => renderPage(<AgendaAppPage nodeId={page.id} />)}
                        />
                      );
                    } else {
                      return (
                        <Route key={page.id} path={`/:languageKey/cms${page.alias}`} render={() => renderPage(<CMSContent nodeId={page.id} />)} />
                      );
                    }
                  })}

                  {!isLoadingPages && <Route path="/:languageKey/cms/hash/:alias" render={() => renderPage(<CMSContent isHash />)} />}
                  {!isLoadingPages && <Route path="/:languageKey/cms/:alias" render={() => renderPage(<CMSContent />)} />}
                  {!isLoadingPages && <Route path="/:languageKey/search/:text" render={() => renderPage(<CMSSearch />)} />}
                  <Route path="/:languageKey/catalog" render={() => renderPage(<Catalog />)} />
                  <Route
                    path="/:languageKey/newsletter/unsubscribe/:hashed_key/:product_id"
                    render={() => renderPage(<UnsubscribeFromNewsletter />)}
                  />
                  <Route path="/:languageKey/error" component={ErrorPage} />
                  <Route path="*" component={isLoadingPages ? () => <></> : NotFoundPage} />
                </Switch>
              </WizardProvider>
            </HttpErrorContextProvider>
          </GlobalContext.Provider>
          <CookieClient history={history} lang={language} />
        </Body>
        <Footer
          follow={footerContents.follow}
          contactUs={footerContents.contactUs}
          links={footerContents.links}
          about={footerContents.about}
          translate={translate}
        />
      </Layout>
      {privacyPolicy.showNotification && (
        <PrivacyPolicyNotification
          labels={{
            privacyPolicy: translate("layout.footer.links.privacy-policy"),
            yes: translate("privacy-policy.notification.yes"),
            no: translate("privacy-policy.notification.no"),
            submit: translate("form.submit"),
          }}
          linkUrl={`${window.location.protocol}//${window.location.host}${pathFactory.create("privacy-policy")}`}
          answer={privacyPolicy.answer}
          onChangeAnswer={(answer) => setPrivacyPolicy(safeStateChanger.changeValue(privacyPolicy, "answer", answer))}
          onSubmit={answerPrivacyPolicyNotification}
        />
      )}
    </>
  );
}

export default App;
