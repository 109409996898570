import React, { useContext, useEffect } from "react";
import { CreditCardPayment } from "@sia/style-guide";
import { useTranslator } from "../../common/Translation/Translator.js";
import GlobalContext from "../../contexts/GlobalContext.js";
import Page from "pages/Page";
import { useHistory } from "react-router-dom";

const CreditCardPaymentPage = () => {
  const { language: lang } = useContext(GlobalContext);
  const translate = useTranslator();
  const history = useHistory();

  useEffect(() => {
    // Remove URL parameters on page load while preserving the base path
    const currentPath = window.location.pathname;
    if (window.location.search) {
      history.replace(currentPath);
    }
  }, [history]);

  if (!process.env.REACT_APP_API_BASE_URL) throw new Error("API_BASE_URL is not defined");
  if (!process.env.REACT_APP_DATATRANS_MERCHANT_ID) throw new Error("DATATRANS_MERCHANT_ID is not defined");
  if (!process.env.REACT_APP_DATATRANS_PRODUCTION) throw new Error("DATATRANS_PRODUCTION is not defined");

  return (
    <Page title={translate("datatrans.credit-card-payment.page.title")}>
      <CreditCardPayment
        translate={translate}
        lang={lang}
        endpoint={window.location.href}
        transactionIdUrl={process.env.REACT_APP_API_BASE_URL}
        merchantId={process.env.REACT_APP_DATATRANS_MERCHANT_ID}
        production={process.env.REACT_APP_DATATRANS_PRODUCTION === "1"}
      />
    </Page>
  );
};

export default CreditCardPaymentPage;
